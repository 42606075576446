import { EHeadingStyleVariant, ETextStyleVariant, Heading, Text } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import Image from 'next/image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DELIVERY, PEOPLE, SHIELD_CHECK, STAR_OUTLINE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import { HomeGuidedSearchB } from '@/components/ui/HomeGuidedSearch';
import { HomeSearchWidget } from '@/components/ui/HomeSearchWidget';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useDefaultHomePageAndMetaValues from '@/hooks/useDefaultHomePageAndMetaValues';
import {
  EABExperimentVariationKey,
  OptimizelyFlags,
  useTimedOutOptimizelyDecision,
} from '@/services/experiments';

export const BannerSection = () => {
  const { isMobile } = useBreakpoint();

  const { homepageBanner, homepageBannerMobile } = useDefaultHomePageAndMetaValues();

  const guidedSearchOnHomeScreenDecision = useTimedOutOptimizelyDecision(
    OptimizelyFlags.GUIDED_SEARCH_ON_HOME_SCREEN,
  );

  return (
    <div className="relative flex flex-col lg:min-h-[100vh]">
      <div className="relative flex flex-col flex-grow">
        <div className="absolute inset-0">
          <Image
            src={isMobile ? homepageBannerMobile : homepageBanner}
            role="presentation"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            priority={true}
            quality={45}
          />

          <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,0,0,0.65)] to-[rgba(0,0,0,0)]" />
        </div>

        <div className="relative flex flex-col justify-center flex-grow px-4 pt-24 pb-8 lg:pb-24 lg:px-10 gap-6 lg:gap-8">
          <div
            className="flex flex-col px-2 text-center text-white gap-2"
            data-testid="home-header">
            <Heading variant={EHeadingStyleVariant.H3}>
              <FormattedMessage defaultMessage="Your Outdoorsy adventure starts here" />
            </Heading>

            <Heading variant={EHeadingStyleVariant.H6}>
              <FormattedMessage defaultMessage="Find the perfect RV rental, camper van, or travel trailer for your vacation." />
            </Heading>
          </div>

          {/** Use min height for the search widget wrapper to avoid content layout shift */}
          <div className="relative flex flex-col items-center lg:mb-32 min-h-[420px] lg:min-h-[200px]">
            {guidedSearchOnHomeScreenDecision.resolved
              ? (guidedSearchOnHomeScreenDecision.decision?.variationKey ===
                  EABExperimentVariationKey.ENABLED_B && <HomeGuidedSearchB />) || (
                  <HomeSearchWidget />
                )
              : null}
          </div>
        </div>
      </div>

      <div className="flex justify-center py-6">
        <div className="flex flex-col justify-center lg:flex-wrap lg:flex-row lg:items-center gap-x-8 gap-y-2">
          {[
            {
              icon: STAR_OUTLINE,
              text: <FormattedMessage defaultMessage="1 million customers and counting" />,
              className: 'order-3 lg:order-1',
            },
            {
              icon: PEOPLE,
              text: <FormattedMessage defaultMessage="Real people here to help 24/7" />,
              className: 'order-2',
            },
            {
              icon: SHIELD_CHECK,
              text: <FormattedMessage defaultMessage="24/7 roadside assistance & insurance" />,
              className: 'order-1 lg:order-3',
            },
            {
              icon: DELIVERY,
              text: <FormattedMessage defaultMessage="Get it delivered" />,
              className: 'order-4',
            },
          ].map((feature, index) => (
            <Text
              key={index}
              variant={ETextStyleVariant.SmallRegular}
              className={cn('flex items-center gap-3 shrink-0', feature.className)}>
              <Icon name={feature.icon} size={IconSize.normal} />
              {feature.text}
            </Text>
          ))}
        </div>
      </div>
    </div>
  );
};
