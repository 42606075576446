import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { MAX_RECENT_SEARCHES } from '@/hooks/useRecentSearchesWithFilters';
import { mapLocationLabelToGroupName, setSurfacedLocation } from '@/utility/surfacedLocation';

import { FilterContent, TFilterContentSharedProps } from './FilterContent';
import { useLocationFilterContext } from './LocationFilterContext';

export type TLocationFilterContentProps = TFilterContentSharedProps & {
  onDataSelection?: () => void;
};

export const LocationFilterContent = ({
  onDataSelection,
  ...props
}: TLocationFilterContentProps) => {
  const intl = useIntl();

  const {
    addressValue,
    setAddressValue,
    suggestions,
    recentSearches,
    popularDestinations,
    navigateToRecentSearch,
  } = useLocationFilterContext();

  // Display search results if they searched.
  // Otherwise fallback to recent searches, and
  // if there are none, display the popular searches.
  const { options, optionsTitle } = (() => {
    if (suggestions.length) {
      return {
        options: suggestions,
        optionsTitle: intl.formatMessage({
          defaultMessage: 'Matches',
          description: 'Global Header > Search',
        }),
      };
    }

    if (recentSearches.length) {
      return {
        options: recentSearches.slice(0, MAX_RECENT_SEARCHES),
        optionsTitle: intl.formatMessage({
          defaultMessage: 'Recent Searches',
          description: 'Global Header > Search',
        }),
      };
    }

    return {
      options: popularDestinations,
      optionsTitle: intl.formatMessage({
        defaultMessage: 'Popular Destinations',
        description: 'Global Header > Search',
      }),
    };
  })();

  return (
    <FilterContent
      header={
        <>
          {intl.formatMessage({
            defaultMessage: 'Where to?',
            description: 'Home Search Widget > Location Modal Title',
          })}
        </>
      }
      content={
        <div className="flex flex-col h-full">
          <div className="flex flex-col gap-2">
            <Text
              variant={ETextStyleVariant.Label}
              component="span"
              className="w-full truncate text-gray-50">
              {intl.formatMessage({
                defaultMessage: 'Location',
                description: 'Home Search Widget > Location Filter Label',
              })}
            </Text>

            <input
              placeholder={intl.formatMessage({
                defaultMessage: 'Type to search',
                description: 'Home Search Widget > Location Filter Input Placeholder',
              })}
              value={addressValue}
              onChange={e => setAddressValue(e.target.value)}
              autoCapitalize="none"
              autoComplete="off"
              spellCheck="false"
              className="pb-2 font-medium tracking-normal text-gray-800 bg-transparent border-b-2 rounded-none highlight placeholder-gray-310 border-gray-50"
            />
          </div>

          <Text variant={ETextStyleVariant.Label} className="pt-6 pb-2 text-gray-500">
            {optionsTitle}
          </Text>

          <div className="flex-grow overflow-auto">
            <ul>
              {options.map(option => (
                <Text
                  key={option.label}
                  variant={ETextStyleVariant.MediumRegular}
                  component="li"
                  role="option"
                  onClick={() => {
                    if (option?.url) {
                      navigateToRecentSearch(option.url);
                      return;
                    }

                    setSurfacedLocation(mapLocationLabelToGroupName(optionsTitle));
                    setAddressValue(option.label);
                    onDataSelection?.();
                  }}
                  className="cursor-pointer py-1.5">
                  {option.label}
                </Text>
              ))}
            </ul>
          </div>
        </div>
      }
      {...props}
    />
  );
};
