import {
  Banner,
  Button,
  EBannerBackgroundVariant,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EPaginationVariant,
  ETextStyleVariant,
  Heading,
  Pagination,
  Text,
} from '@outdoorsyco/bonfire';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import Link from '@/components/switchback/Link/Link';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IPromoHomepageCarouselItemMetadata } from '@/services/types/promos/promos';

interface Props {
  item: IPromoHomepageCarouselItemMetadata;
  setActiveSlide: (activeSlide: number) => void;
  totalPages: number;
  activeSlide: number;
}

export const PromosCarouselItem: React.FC<Props> = ({
  item,
  setActiveSlide,
  totalPages,
  activeSlide,
}) => {
  const intl = useIntl();
  const formattedDescription = item.description.replace(/<\/?p>/g, '');
  const { isAboveDesktop } = useBreakpoint();

  const handlePageChange = (page: number) => {
    setActiveSlide(page - 1);
  };

  return (
    <Banner
      backgroundVariant={EBannerBackgroundVariant.Canvas}
      className="h-full !bg-green-800"
      withBorderRadius
      withBoxShadow>
      <Banner.Content className="h-full">
        <div
          className={clsx('flex items-center h-full', {
            'h-screen max-h-[400px]': isAboveDesktop,
          })}>
          {isAboveDesktop && (
            <div className="flex flex-1 w-full h-full">
              <Banner.Media>
                <div className="w-full h-full scale-105">
                  <ResponsiveImage src={item.hero_image} className="object-cover w-full h-full" />
                </div>
              </Banner.Media>
            </div>
          )}
          <div
            className={clsx('flex flex-col flex-1 h-full', {
              'p-20 max-w-1/2': isAboveDesktop,
              'p-6 items-center justify-center text-center': !isAboveDesktop,
            })}>
            <div className="pb-4">
              <Heading className="text-white" variant={EHeadingStyleVariant.H5}>
                {item.title}
              </Heading>
            </div>
            <div className="pb-8 grow">
              <Text
                className="text-white"
                dangerouslySetInnerHTML={{ __html: formattedDescription }}
                variant={ETextStyleVariant.MediumRegular}
              />
            </div>
            <div>
              <Link href={item.url_path} passHref legacy forceClientsideRouting>
                <Button
                  onDark
                  variant={EButtonColorVariant.Secondary}
                  label={intl.formatMessage({ defaultMessage: 'Explore vehicles' })}
                  fullWidth={false}
                />
              </Link>
            </div>
            {!isAboveDesktop && totalPages > 1 && (
              <Pagination
                ariaLabel="pagination"
                onChange={handlePageChange}
                totalPages={totalPages}
                variant={EPaginationVariant.Dots}
                pageNumber={activeSlide + 1}
                className="flex justify-center pt-5"
              />
            )}
          </div>
        </div>
      </Banner.Content>
    </Banner>
  );
};
