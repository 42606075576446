import { ELinkVariant, ETextStyleVariant, Icon, Link, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { HomeGuidedSearch } from './HomeGuidedSearch';

export const HomeGuidedSearchB = () => {
  const intl = useIntl();

  return (
    <HomeGuidedSearch
      TriggerComponent={({ showGuidedSearch }) => {
        return (
          <div className="w-full max-w-lg">
            <button
              onClick={showGuidedSearch}
              className="flex items-center justify-between w-full p-2 pl-4 bg-white rounded-full lg:p-4 lg:pl-8 gap-4">
              <Text variant={ETextStyleVariant.LargeRegular} component="span" className="truncate">
                <span className="hidden lg:block">
                  {intl.formatMessage({
                    defaultMessage: 'Need help finding the perfect rig? Try our guided search',
                  })}
                </span>

                <span className="lg:hidden">
                  {intl.formatMessage({
                    defaultMessage: 'Try our guided search',
                  })}
                </span>
              </Text>

              <span className="flex items-center justify-center w-12 h-12 text-white rounded-full bg-bark-30">
                <Icon name="General.Search" width={24} height={24} />
              </span>
            </button>

            <div className="flex justify-center w-full">
              <Link
                onDark
                href="/rv-search"
                textVariant={ETextStyleVariant.MediumBold}
                variant={ELinkVariant.Underline}
                label={intl.formatMessage({
                  defaultMessage: 'Search all RV listings now',
                })}
                className="flex px-6 py-2 rounded-b-lg gap-1 bg-primary-800">
                <Icon name="General.Arrow.Right" width={24} height={24} />
              </Link>
            </div>
          </div>
        );
      }}
    />
  );
};
