import {
  ELinkVariant,
  ETagVariant,
  ETextStyleVariant,
  Link,
  Tag,
  Text,
} from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { HomeGuidedSearch } from './HomeGuidedSearch';

type THomeGuidedSearchAProps = {
  containerClassName?: string;
  wrapperClassName?: string;
};

export const HomeGuidedSearchA = ({
  containerClassName,
  wrapperClassName,
}: THomeGuidedSearchAProps) => {
  const intl = useIntl();

  return (
    <HomeGuidedSearch
      TriggerComponent={({ showGuidedSearch }) => {
        return (
          <div className={cn('flex justify-center w-full max-w-lg', containerClassName)}>
            <div
              className={cn(
                'flex flex-col items-center w-full p-4 text-white rounded-b-2xl lg:rounded-b-lg lg:w-auto lg:py-2 lg:flex-row bg-primary-800 gap-2',
                wrapperClassName,
              )}>
              <Tag
                variant={ETagVariant.Canvas}
                label={intl.formatMessage({ defaultMessage: 'New' })}
                className="hidden lg:block"
              />

              <Text variant={ETextStyleVariant.MediumRegular}>
                {intl.formatMessage({
                  defaultMessage: 'Need help finding the perfect rig?',
                })}
              </Text>

              <Link
                onDark
                textVariant={ETextStyleVariant.MediumBold}
                variant={ELinkVariant.Underline}
                label={intl.formatMessage({
                  defaultMessage: 'Try our guided search',
                })}
                onClick={showGuidedSearch}
              />
            </div>
          </div>
        );
      }}
    />
  );
};
