import React from 'react';
import { useIntl } from 'react-intl';

import { FilterButton } from './FilterButton';
import { useLocationFilterContext } from './LocationFilterContext';

type TLocationFilterButtonProps = {
  toggleFilter: () => void;
  className?: string;
};

export const LocationFilterButton = ({ toggleFilter, className }: TLocationFilterButtonProps) => {
  const intl = useIntl();

  const { addressValue } = useLocationFilterContext();

  return (
    <FilterButton
      aria-label={intl.formatMessage({
        defaultMessage: 'Click to select the location',
        description: 'Home Search Widget > Location Filter Aria Label',
      })}
      label={intl.formatMessage({
        defaultMessage: 'Location',
        description: 'Home Search Widget > Location Filter Label',
      })}
      value={
        addressValue ||
        intl.formatMessage({
          defaultMessage: 'Where to?',
          description: 'Home Search Widget > Location Filter Placeholder',
        })
      }
      onClick={toggleFilter}
      className={className}
    />
  );
};
