import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';

import { calculateOptionIndex } from '@/utility/autoCompleteGroupedOptions';

import { DesktopFilterContent } from './DesktopFilterContent';
import { useLocationAutocompleteContext } from './LocationAutocompleteContext';

export const LocationAutocompleteMenu = () => {
  const { isOpened, menuProps, allGroupedOptions, groupedOptions, getOptionProps } =
    useLocationAutocompleteContext();

  return (
    <DesktopFilterContent
      isOpened={isOpened}
      content={
        <div {...menuProps} className="grid grid-flow-col">
          {groupedOptions.map((groupOption, groupIndex) => (
            <div key={groupIndex}>
              <Text variant={ETextStyleVariant.Label} className="px-5 pb-4 text-gray-500">
                {groupOption.group}
              </Text>

              <ul>
                {groupOption.options.map((option, optionIndex) => (
                  <Text
                    key={option.label}
                    variant={ETextStyleVariant.MediumRegular}
                    component="li"
                    {...getOptionProps({
                      option,
                      index: calculateOptionIndex(option.groupName, optionIndex, allGroupedOptions),
                    })}
                    className="px-5 cursor-pointer py-1.5 hover:bg-gray-100">
                    {option.label}
                  </Text>
                ))}
              </ul>
            </div>
          ))}
        </div>
      }
      className="px-0"
    />
  );
};
