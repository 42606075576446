import { Button, Divider, EButtonColorVariant, EDividerVariant } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { HomeGuidedSearchA } from '@/components/ui/HomeGuidedSearch';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRouter } from '@/hooks/useRouter';
import {
  EABExperimentVariationKey,
  OptimizelyFlags,
  useOptimizelyDecision,
} from '@/services/experiments';
import { yieldToMain } from '@/utility/yieldToMain';

import { useApplySearch } from './applySearch';
import { DateFilterButton } from './DateFilterButton';
import { DateFilterContent } from './DateFilterContent';
import { GuestsFilterButton } from './GuestsFilterButton';
import { GuestsFilterContent } from './GuestsFilterContent';
import { LocationAutocompleteProvider } from './LocationAutocompleteContext';
import { LocationAutocompleteMenu } from './LocationAutocompleteMenu';
import { LocationFilterButton } from './LocationFilterButton';
import { LocationFilterContent } from './LocationFilterContent';
import { LocationFilterProvider } from './LocationFilterContext';
import { LocationFilterInput } from './LocationFilterInput';
import { VehicleTypeFilterButton } from './VehicleTypeFilterButton';
import { VehicleTypeFilterContent } from './VehicleTypeFilterContent';

enum EHomeSearchWidgetFilter {
  Location = 'Location',
  Date = 'Date',
  Guests = 'Guests',
  VehicleType = 'Vehicle Type',
}

export const HomeSearchWidget = () => {
  const intl = useIntl();
  const router = useRouter();

  const { isAboveDesktop } = useBreakpoint();

  const guidedSearchOnHomeScreenDecision = useOptimizelyDecision(
    OptimizelyFlags.GUIDED_SEARCH_ON_HOME_SCREEN,
  );

  const showGuidedSearchA =
    EABExperimentVariationKey.ENABLED_A === guidedSearchOnHomeScreenDecision?.variationKey;

  const containerRef = useRef<HTMLFormElement>(null);

  const [openedFilter, setOpenedFilter] = useState<EHomeSearchWidgetFilter>();

  const setOpenedFilterYTM = (filter?: EHomeSearchWidgetFilter) => {
    yieldToMain(() => setOpenedFilter(filter));
  };

  const toggleTargetFilter = (targetFilter: EHomeSearchWidgetFilter) => {
    return () => setOpenedFilterYTM(openedFilter === targetFilter ? undefined : targetFilter);
  };

  const [, { update: updatePrevBaseUrl }] = useLocalStorage('prevBaseUrl');
  const applySearch = useApplySearch();

  const handleSearch = async () => {
    setOpenedFilter(undefined);
    updatePrevBaseUrl(router.route);
    await applySearch();
  };

  useEffect(() => {
    // We are using dialogs for mobile, so we don't need to handle clicks outside the container
    if (!isAboveDesktop) return;

    const handlePressEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenedFilterYTM(undefined);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpenedFilterYTM(undefined);
      }
    };

    document.addEventListener('keydown', handlePressEscape, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handlePressEscape, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isAboveDesktop]);

  return (
    <div className="w-full lg:max-w-lg">
      <form
        ref={containerRef}
        onSubmit={async e => {
          e.preventDefault();
          await handleSearch();
        }}
        className={cn(
          'relative flex flex-col items-center p-4 bg-white gap-4 rounded-2xl',
          'lg:gap-0 lg:p-0 lg:rounded-[38px] lg:flex-row',
          {
            'lg:bg-gray-100 lg:rounded-b-none': openedFilter,
            'rounded-b-none': showGuidedSearchA,
          },
        )}>
        <div
          className={cn('flex-grow w-full', 'lg:w-auto lg:flex-grow lg:pr-2 lg:pl-4', {
            'lg:bg-white lg:rounded-l-[inherit]': openedFilter === EHomeSearchWidgetFilter.Location,
          })}>
          <LocationFilterProvider
            onNavigateToRecentSearch={() => {
              setOpenedFilter(undefined);
            }}>
            {isAboveDesktop && (
              <LocationAutocompleteProvider
                isOpened={openedFilter === EHomeSearchWidgetFilter.Location}
                onOpen={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Location)}
                onDataSelection={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}>
                <LocationFilterInput />
                <LocationAutocompleteMenu />
              </LocationAutocompleteProvider>
            )}

            {!isAboveDesktop && (
              <>
                <LocationFilterButton
                  toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.Location)}
                />

                <LocationFilterContent
                  onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
                  nextButtonLabel={intl.formatMessage({
                    defaultMessage: 'Next: enter dates',
                    description: 'Home Search Widget > Next to dates',
                  })}
                  isOpened={openedFilter === EHomeSearchWidgetFilter.Location}
                  onClose={() => setOpenedFilterYTM(undefined)}
                  onDataSelection={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
                />
              </>
            )}
          </LocationFilterProvider>
        </div>

        <Divider
          height="44px"
          variant={EDividerVariant.Vertical}
          className={cn('hidden', 'lg:block')}
        />

        <div className={cn('flex items-center w-full', 'lg:w-auto')}>
          <div
            className={cn('w-full', 'lg:w-[165px] lg:px-2', {
              'lg:bg-white': openedFilter === EHomeSearchWidgetFilter.Date,
            })}>
            <DateFilterButton
              toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.Date)}
              className="border-r-0 rounded-r-none"
            />

            <DateFilterContent
              onDataSelection={() => {
                // Move automatically only for desktop view, mobile has its own controls.
                isAboveDesktop && setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests);
              }}
              onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Location)}
              onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
              nextButtonLabel={intl.formatMessage({
                defaultMessage: 'Next: guest details',
                description: 'Home Search Widget > Next to guests',
              })}
              isOpened={openedFilter === EHomeSearchWidgetFilter.Date}
              onClose={() => setOpenedFilterYTM(undefined)}
            />
          </div>

          <Divider
            height="44px"
            variant={EDividerVariant.Vertical}
            className={cn(
              'absolute left-1/2 -translate-x-1/2',
              'lg:relative lg:left-0 lg:translate-x-0',
            )}
          />

          <div
            className={cn('w-full', 'lg:w-[150px] lg:px-2', {
              'lg:bg-white': openedFilter === EHomeSearchWidgetFilter.Guests,
            })}>
            <GuestsFilterButton
              toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.Guests)}
              className="border-l-0 rounded-l-none"
            />

            <GuestsFilterContent
              onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
              onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.VehicleType)}
              nextButtonLabel={intl.formatMessage({
                defaultMessage: 'Next: vehicle type',
                description: 'Home Search Widget > Next to vehicle type',
              })}
              isOpened={openedFilter === EHomeSearchWidgetFilter.Guests}
              onClose={() => setOpenedFilterYTM(undefined)}
            />
          </div>
        </div>

        <Divider
          height="44px"
          variant={EDividerVariant.Vertical}
          className={cn('hidden', 'lg:block')}
        />

        <div
          className={cn(
            'flex flex-col items-center w-full gap-4',
            'lg:flex-row lg:w-[350px] lg:pl-2 lg:pr-4',
            {
              'lg:bg-white lg:rounded-r-[inherit]':
                openedFilter === EHomeSearchWidgetFilter.VehicleType,
            },
          )}>
          <VehicleTypeFilterButton
            toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.VehicleType)}
          />

          <VehicleTypeFilterContent
            onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
            onSearch={handleSearch}
            isOpened={openedFilter === EHomeSearchWidgetFilter.VehicleType}
            onClose={() => setOpenedFilterYTM(undefined)}
          />

          <Button
            type="submit"
            label={intl.formatMessage({
              defaultMessage: 'Search',
              description: 'Home Search Widget > Search Button Label',
            })}
            variant={EButtonColorVariant.Primary}
            className="w-full lg:w-auto"
          />
        </div>
      </form>

      {showGuidedSearchA && <HomeGuidedSearchA />}
    </div>
  );
};
