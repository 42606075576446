import {
  Button,
  Divider,
  EButtonColorVariant,
  ELinkVariant,
  EModalFooterVariants,
  EModalVariants,
  Link,
  Modal,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { useBreakpoint } from '@/hooks/useBreakpoint';

import { DesktopFilterContent } from './DesktopFilterContent';

type TTFilterContentNextProps = {
  onNext: () => void;
  nextButtonLabel?: string;
  onSearch?: undefined;
};

type TTFilterContentSearchProps = {
  onNext?: undefined;
  nextButtonLabel?: undefined;
  onSearch?: () => void;
};

export type TFilterContentSharedProps = (TTFilterContentNextProps | TTFilterContentSearchProps) & {
  onBack?: () => void;
  isOpened: boolean;
  onClose: () => void;
};

type TFilterContentProps = TFilterContentSharedProps & {
  header: React.ReactNode;
  content: React.ReactNode;
};

export const FilterContent = ({
  onBack,
  onNext,
  nextButtonLabel,
  onSearch,
  header,
  content,
  isOpened,
  onClose,
}: TFilterContentProps) => {
  const intl = useIntl();
  const { isAboveDesktop } = useBreakpoint();

  if (isAboveDesktop) {
    return <DesktopFilterContent isOpened={isOpened} content={content} />;
  }

  return (
    <Modal isOpen={isOpened} onClose={onClose} variant={EModalVariants.Full}>
      <Modal.Actions />

      <Modal.Header>
        <div className="mb-6">{header}</div>
        <Divider />
      </Modal.Header>

      <Modal.Content className="py-6">{content}</Modal.Content>

      {(onBack || onNext || onSearch) && (
        <>
          <Modal.Divider />
          <Modal.Footer variant={EModalFooterVariants.Actions}>
            {(onBack && (
              <Link
                variant={ELinkVariant.Underline}
                label={intl.formatMessage({
                  defaultMessage: 'Back',
                  description: 'Home Search Widget > Back Button Label',
                })}
                onClick={onBack}
              />
            )) || <div />}

            {onNext && (
              <Button
                variant={EButtonColorVariant.Primary}
                label={
                  nextButtonLabel ||
                  intl.formatMessage({
                    defaultMessage: 'Next',
                    description: 'Home Search Widget > Next Button Label',
                  })
                }
                onClick={onNext}
              />
            )}

            {onSearch && (
              <Button
                variant={EButtonColorVariant.Primary}
                label={intl.formatMessage({
                  defaultMessage: 'Search',
                  description: 'Home Search Widget > Search Button Label',
                })}
                onClick={onSearch}
              />
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
