import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { useLocationAutocompleteContext } from './LocationAutocompleteContext';

type TLocationFilterInputProps = {
  className?: string;
};

export const LocationFilterInput = ({ className }: TLocationFilterInputProps) => {
  const intl = useIntl();

  const { isOpened, inputProps } = useLocationAutocompleteContext();

  return (
    <div
      className={cn(
        'relative flex flex-col gap-2 p-4 w-full text-left overflow-hidden',
        className,
      )}>
      <Text
        variant={ETextStyleVariant.Label}
        component="span"
        className="w-full truncate text-gray-50">
        {intl.formatMessage({
          defaultMessage: 'Location',
          description: 'Home Search Widget > Location Filter Label',
        })}
      </Text>

      <Text
        variant={ETextStyleVariant.MediumBold}
        component="span"
        className={cn('w-full text-gray-800 truncate', {
          invisible: isOpened || inputProps.value,
        })}>
        {intl.formatMessage({
          defaultMessage: 'Where to?',
          description: 'Home Search Widget > Location Filter Placeholder',
        })}
      </Text>

      <input
        {...inputProps}
        placeholder={intl.formatMessage({
          defaultMessage: 'Type to search',
          description: 'Home Search Widget > Location Filter Input Placeholder',
        })}
        className={cn(
          'absolute inset-0 z-10 bg-transparent p-4 pt-9 text-ellipsis',
          'font-medium text-gray-800 highlight tracking-normal',
          {
            'placeholder-transparent': !isOpened,
            'placeholder-gray-310': isOpened,
          },
        )}
      />

      {isOpened && <div className="absolute left-0 z-20 w-full bottom-1 h-0.5 bg-gray-50" />}
    </div>
  );
};
