import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Bayfield from '@/assets/home/bayfield.jpg';
import Yosemite from '@/assets/home/yosemite.jpg';
import { ArrowVariants } from '@/components/switchback/Carousel/Arrow';
import Carousel, { Spacing } from '@/components/switchback/Carousel/Carousel';
import { breakpoint } from '@/constants/breakpoint';
import useWindowSize from '@/hooks/useWindowSize';
import { isProdDeploy } from '@/utility/isSSR';

import { DestinationCard } from './DestinationCard';
import css from './SignatureDestinations.module.css';

export const DestinationCarousel = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const windowWidth = useWindowSize()?.width || 0;
  const intl = useIntl();

  useEffect(() => {
    setIsDesktop(windowWidth >= breakpoint.md);
  }, [windowWidth]);

  const isProd = isProdDeploy();

  const destinations = [
    {
      name: intl.formatMessage({ defaultMessage: 'Yosemite, CA' }),
      imageSrc: Yosemite,
      url: isProd
        ? '/campground-rental/bass-lake_ca/outdoorsy-yosemite_2-listing?cam=homepage_odn'
        : '/campground-rental/bass-lake_ca/bass-lake-at-yosemite_10-listing',
      wpURL: isProd
        ? 'https://www.outdoorsy.com/destinations/outdoorsy-yosemite'
        : 'https://www.staging.outdoorsy.com/destinations/outdoorsy-yosemite',
    },

    {
      name: intl.formatMessage({ defaultMessage: 'Bayfield, CO' }),
      imageSrc: Bayfield,
      url: isProd
        ? '/campground-rental/bayfield_co/outdoorsy-bayfield_3-listing?cam=homepage_odn'
        : '/campground-rental/bayfield_co/training-outdoorsy-bayfield_13-listing',
      wpURL: isProd
        ? 'https://www.outdoorsy.com/destinations/outdoorsy-bayfield'
        : 'https://www.staging.outdoorsy.com/destinations/outdoorsy-bayfield',
    },
  ];

  return (
    <>
      {isDesktop ? (
        <div className={`${css.carouselWrapper}`}>
          <Carousel
            arrows={true}
            componentName="destination-carousel"
            slidesToShow={2}
            slidesToScroll={2}
            hasShadow={false}
            spacing={Spacing.large}
            infinite={false}
            swipeToSlide={true}
            arrowVariant={ArrowVariants.outside}>
            {destinations.map(({ name, imageSrc, url, wpURL }, index) => (
              <DestinationCard
                key={index}
                imageSrc={imageSrc}
                url={url}
                name={name}
                wpURL={wpURL}
              />
            ))}
          </Carousel>
        </div>
      ) : (
        <ul className={`pt-0 pb-6 ${css.scrollDestSectionWrapper}`}>
          {destinations.map(({ name, imageSrc, url, wpURL }, index) => (
            <DestinationCard key={index} imageSrc={imageSrc} url={url} name={name} wpURL={wpURL} />
          ))}
        </ul>
      )}
    </>
  );
};
