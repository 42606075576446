import { EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DestinationCarousel } from './DestinationCarousel';
import css from './SignatureDestinations.module.css';

export const SignatureDestinations = () => {
  return (
    <div className={css.wrapper}>
      <Heading variant={EHeadingStyleVariant.H4} className="mt-2 mb-8">
        <FormattedMessage defaultMessage="Book a stay at our Signature Destinations" />
      </Heading>
      <DestinationCarousel />
    </div>
  );
};
