import useAutocomplete from '@material-ui/lab/useAutocomplete';
import React, { createContext, useContext } from 'react';
import { useIntl } from 'react-intl';

import { MAX_RECENT_SEARCHES } from '@/hooks/useRecentSearchesWithFilters';
import { IGroupOption, IOption, limitRecentSearches } from '@/utility/autoCompleteGroupedOptions';
import { stripDiacritics } from '@/utility/stripDiacritics';
import {
  LocationGroupType,
  mapLocationLabelToGroupName,
  setSurfacedLocation,
} from '@/utility/surfacedLocation';

import { useLocationFilterContext } from './LocationFilterContext';

type TLocationAutocompleteContext = {
  isOpened: boolean;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  menuProps: React.HTMLAttributes<HTMLDivElement>;
  allGroupedOptions: IGroupOption[];
  groupedOptions: IGroupOption[];
  getOptionProps: ({
    option,
    index,
  }: {
    option: IOption;
    index: number;
  }) => React.HTMLAttributes<React.ElementType>;
};

const LocationAutocompleteContext = createContext<TLocationAutocompleteContext>({
  isOpened: false,
  inputProps: {},
  menuProps: {},
  allGroupedOptions: [],
  groupedOptions: [],
  getOptionProps: () => ({}),
});

export const useLocationAutocompleteContext = () => useContext(LocationAutocompleteContext);

type TLocationAutocompleteProviderProps = {
  isOpened: boolean;
  onOpen: () => void;
  onDataSelection?: () => void;
};

export const LocationAutocompleteProvider: React.FC<TLocationAutocompleteProviderProps> = ({
  isOpened,
  onOpen,
  onDataSelection,
  children,
}) => {
  const intl = useIntl();

  const {
    addressValue,
    setAddressValue,
    suggestions,
    recentSearches,
    popularDestinations,
    navigateToRecentSearch,
  } = useLocationFilterContext();

  const optionGroupLabels = {
    suggestions: intl.formatMessage({
      defaultMessage: 'Matches',
      description: 'Global Header > Search',
    }),
    recentSearches: intl.formatMessage({
      defaultMessage: 'Recent Searches',
      description: 'Global Header > Search',
    }),
    popularDestinations: intl.formatMessage({
      defaultMessage: 'Popular Destinations',
      description: 'Global Header > Search',
    }),
  };

  const handleAddressChange = (value: string) => {
    setAddressValue(value);
    setSurfacedLocation(LocationGroupType.OTHER);
  };

  const handleAddressSelect = (value: IOption) => {
    if (value?.url) {
      navigateToRecentSearch(value.url);
      return;
    }

    setSurfacedLocation(mapLocationLabelToGroupName(value?.groupName));
    onDataSelection?.();
  };

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    inputValue: addressValue,
    options: [
      ...suggestions.map(option => ({
        ...option,
        groupName: optionGroupLabels.suggestions,
      })),
      ...recentSearches.map(option => ({
        ...option,
        groupName: optionGroupLabels.recentSearches,
      })),
      ...popularDestinations.map(option => ({
        ...option,
        groupName: optionGroupLabels.popularDestinations,
      })),
    ],
    filterOptions: (autocompleteOptions, autocompleteState) => {
      const input = stripDiacritics(autocompleteState.inputValue.trim().toLowerCase());

      return !input
        ? autocompleteOptions
        : autocompleteOptions.filter(autocompleteOption => {
            // Do not filter suggestions, those are already filtered by the API
            if (autocompleteOption.groupName === optionGroupLabels.suggestions) {
              return autocompleteOption;
            }

            const candidate = stripDiacritics(
              autocompleteState.getOptionLabel(autocompleteOption).trim().toLowerCase(),
            );

            return candidate.indexOf(input) > -1;
          });
    },
    getOptionLabel: option => option.label,
    groupBy: option => option.groupName,
    onInputChange: (_, value) => handleAddressChange(value),
    onChange: (_, value) => handleAddressSelect(value as unknown as IOption),
    onOpen: onOpen,
    freeSolo: true,
    open: isOpened,
  });

  return (
    <LocationAutocompleteContext.Provider
      value={{
        isOpened,
        inputProps: getInputProps(),
        menuProps: getListboxProps(),
        allGroupedOptions: groupedOptions as unknown as IGroupOption[],
        groupedOptions: limitRecentSearches(
          groupedOptions as unknown as IGroupOption[],
          intl.formatMessage({
            defaultMessage: 'Recent Searches',
            description: 'Global Header > Search',
          }),
          MAX_RECENT_SEARCHES,
        ),
        getOptionProps,
      }}>
      {children}
    </LocationAutocompleteContext.Provider>
  );
};
