import React from 'react';

import { useRouter } from '@/hooks/useRouter';
import { trackGuidedSearchSelected } from '@/services/analytics/search';

type THomeGuidedSearchProps = {
  TriggerComponent: React.ComponentType<{ showGuidedSearch: () => void }>;
};

export const HomeGuidedSearch = ({ TriggerComponent }: THomeGuidedSearchProps) => {
  const router = useRouter();

  return (
    <TriggerComponent
      showGuidedSearch={() => {
        trackGuidedSearchSelected({ source: 'home_search' });
        router.push('guided-search', undefined, {
          shallow: true,
        });
      }}
    />
  );
};
